import React from "react"

import Container from "react-bootstrap/Container"

import LayoutIndex from "../components/layoutIndex"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <LayoutIndex>
    <SEO title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </LayoutIndex>
)

export default NotFoundPage
